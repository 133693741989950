
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faArrowRight as farArrowRight,
    } from '@fortawesome/pro-regular-svg-icons';

    export default {
        name: 'GlowUpCareersCta',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                showPromptModal: false,
                loginPrompt: {
                    component_name: 'LoginPrompt',
                    layout: 'sm',
                    code: 'LOGIN_PROMPT',
                    page_referral_element: '',
                    site_position: 'login_signup_popup',
                    title: 'Login or sign up',
                    options: {
                        employer_site: false,
                    },
                },
                icons: {
                    farArrowRight,
                },
            };
        },

        methods: {
            openPromptModal(pageReferralElement) {
                this.loginPrompt.page_referral_element = pageReferralElement;
                this.showPromptModal = true;
            },

            closePromptModal() {
                this.showPromptModal = false;
            },
        },
    };
