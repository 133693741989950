
    export default {
        name: 'GlowUpCareersDiscover',

        data() {
            return {
                showPromptModal: false,
                loginPrompt: {
                    component_name: 'LoginPrompt',
                    layout: 'sm',
                    code: 'LOGIN_PROMPT',
                    page_referral_element: '',
                    site_position: 'login_signup_popup',
                    title: 'Login or sign up',
                    options: {
                        employer_site: false,
                    },
                },
            };
        },

        methods: {
            openPromptModal(pageReferralElement) {
                this.loginPrompt.page_referral_element = pageReferralElement;
                this.showPromptModal = true;
            },

            closePromptModal() {
                this.showPromptModal = false;
            },
        },
    };
