
    import { mapState } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faChevronDown as farChevronDown,
        faArrowRight as farArrowRight,
        faEllipsis as farEllipsis,
    } from '@fortawesome/pro-regular-svg-icons';

    export default {
        name: 'GlowUpCareersEeoPopup',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                eeoTags: [],
                icons: {
                    farChevronDown,
                    farArrowRight,
                    farEllipsis,
                },
                isModalOpen: false,
            };
        },

        computed: {
            ...mapState({
                $_search: state => state.search,
            }),

            selectedEeoTags() {
                return this.eeoTags.filter(item => item.isActive);
            },
        },

        mounted() {
            this.loadEeoTags();
        },

        methods: {
            loadEeoTags() {
                this.eeoTags = this.$_search.availableEeoTags.map(item => ({
                    isActive: false,
                    ...item,
                }));
            },

            toggleEeoTag(eeoTag) {
                this.eeoTags.find(item => item.id === eeoTag.id).isActive = !eeoTag.isActive;
            },

            handleSearchJobs() {
                const eeoTags = this.eeoTags.filter(item => item.isActive).map(item => item.id);
                const param = `/eo_${eeoTags.join('_')}`;
                this.$router.push({
                    path: this.$jobSearchDefaultUrlQuery + param,
                });
            },

            togglePopup() {
                this.isModalOpen = !this.isModalOpen;
            },
        },
    };
