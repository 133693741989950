
    import GlowUpCareersHero from '@/components/Landings/GlowUpCareers/GlowUpCareersHero';
    import GlowUpCareersUsp from '@/components/Landings/GlowUpCareers/GlowUpCareersUsp';
    import GlowUpCareersDiscover from '@/components/Landings/GlowUpCareers/GlowUpCareersDiscover';
    import GlowUpCareersEeo from '@/components/Landings/GlowUpCareers/GlowUpCareersEeo';
    import GlowUpCareersEeoPopup from '@/components/Landings/GlowUpCareers/GlowUpCareersEeoPopup';
    import GlowUpCareersCta from '@/components/Landings/GlowUpCareers/GlowUpCareersCta';

    export default {
        name: 'GlowUpCareersHome',

        components: {
            GlowUpCareersHero,
            GlowUpCareersUsp,
            GlowUpCareersDiscover,
            GlowUpCareersEeo,
            GlowUpCareersEeoPopup,
            GlowUpCareersCta,
        },
    };
