
    import { mapState } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faArrowRight as farArrowRight,
    } from '@fortawesome/pro-regular-svg-icons';

    export default {
        name: 'GlowUpCareersEeo',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                eeoTags: [],
                columnAEeoIds: [4, 7, 2, 14],
                columnBEeoIds: [5, 1, 9, 11, 10],
                columnCEeoIds: [13, 8, 12, 15],
                icons: {
                    farArrowRight,
                },
            };
        },

        computed: {
            ...mapState({
                $_search: state => state.search,
            }),

            eeoTagsOptionsA() {
                return this.eeoTags.filter(item => this.columnAEeoIds.includes(item.id));
            },
            eeoTagsOptionsB() {
                return this.eeoTags.filter(item => this.columnBEeoIds.includes(item.id));
            },
            eeoTagsOptionsC() {
                return this.eeoTags.filter(item => this.columnCEeoIds.includes(item.id));
            },
            isEeoCtaDisabled() {
                return !this.eeoTags.some(item => item.isActive);
            },
        },

        async mounted() {
            await this.loadEeoTags();
        },

        methods: {
            loadEeoTags() {
                this.eeoTags = this.$_search.availableEeoTags.map(item => ({
                    isActive: false,
                    ...item,
                }));
            },
            toggleEeoTag(eeoTag) {
                this.eeoTags.find(item => item.id === eeoTag.id).isActive = !eeoTag.isActive;
            },

            handleSearchJobs() {
                const eeoTags = this.eeoTags.filter(item => item.isActive).map(item => item.id);
                const param = `/eo_${eeoTags.join('_')}`;
                this.$store.commit('search/SET', { key: 'searchBlurbText', value: false });
                this.$router.push({
                    path: this.$jobSearchDefaultUrlQuery + param,
                });
            },
        },
    };
